import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);
import "@/utils/rem.js";
import Vant from "vant";
import "vant/lib/index.css";

import common from "@/assets/common.js";
app.config.globalProperties.$common = common;
import axios from "axios";
app.config.globalProperties.$axios = axios;
import Qs from "qs";
app.config.globalProperties.$Qs = Qs;

app.use(Vant);
app.use(router);

app.mount("#app");

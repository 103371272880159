export default {
    //判断是否微信登陆
    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    },

    // 判断是否是移动端
    isYi() {
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            return true
        }
    },

    // 判断是否是PC端
    isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
        }
        return flag;
    },

    // 判断安卓
    isAndroid() {
        var u = navigator.userAgent;
        if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
            return true;
        }
        return false;
    },

    // 判断设备为 ios
    // isIos() {
    //   var u = navigator.userAgent;
    //   if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
    //     return true;
    //   }
    //   return false;
    // },
    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (/iPhone|mac|iPod|iPad/i.test(u)) {
            return true;
        }
        return false;
    },

    // 判断是否是PC端，如果是，宽度为800px
    getWepPcInfo(id) {
        if (this.isPC()) {
            const wepapp = document.querySelector(id);
            wepapp.style.width = "800px";
            wepapp.style.margin = "0 auto";
        }
    },

    // 实体字符转换
    entityToString(entity) {
        var div = document.createElement("div");
        div.innerHTML = entity;
        var res = div.innerText || div.textContent;
        this.mytext = res;
        return res;
    },


}